<template>
    <Layout>
        <div class="card mb-0" style="overflow-y: auto; overflow-x: hidden">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-6 pe-1 pb-1 dashboard-transaction">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 100%">
                            <LoadingTable
                                :cols="4"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="agent_deposit_loading" />

                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="player-deposit">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="8">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t('store charging')
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/agent/transaction/deposit">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <!-- <th scope="col">
                                            {{ $t('referrer') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('charging amount') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('physical bank') }}
                                        </th>
                                        <!-- <th scope="col" class="">
                                            {{ $t('virtual bank') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in agent_deposit_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'agent'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'agent'
                                                        )
                                                    "
                                                    class="link-username"
                                                    >
                                                    <span :class="`lvl lvl-${r.user.agent.level}`">{{$t(`level ${r.user.agent.level}`)}}</span>
                                                    <!-- <i
                                                        :class="`mdi mdi-numeric-${
                                                            r.user.agent.level || 0
                                                        }-box fs-5 level-${
                                                            r.user.agent
                                                                .level || 0
                                                        }`">
                                                    </i> -->
                                                    {{ r.user.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                        ? r.handler_bank
                                                              .bank_name + '/'
                                                        : ''
                                                }}
                                                {{
                                                    r.handler_bank
                                                        .account_number
                                                        ? r.handler_bank
                                                              .account_number
                                                        : ''
                                                }}{{
                                                    r.handler_bank
                                                        .account_holder
                                                        ? '/' +
                                                          r.handler_bank
                                                              .account_holder
                                                        : ''
                                                }}
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            {{
                                                r.customer_bank.bank_name
                                                    ? r.customer_bank
                                                          .bank_name + '/'
                                                    : ''
                                            }}
                                            {{
                                                r.customer_bank.account_number
                                                    ? r.customer_bank
                                                          .account_number
                                                    : ''
                                            }}{{
                                                r.customer_bank.account_holder
                                                    ? '/' +
                                                      r.customer_bank
                                                          .account_holder
                                                    : ''
                                            }}
                                        </td> -->
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div class="td-username">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-secondary"
                                                        @click="
                                                            goToTicketForm(
                                                                r.user.username,
                                                                'agent'
                                                            )
                                                        ">
                                                        <i
                                                            class="bx bx-envelope bx-tada-hover fs-4"></i
                                                    ></a>

                                                    <a
                                                        :title="
                                                            $t(`click to view`)
                                                        "
                                                        href="javascript:void(0)"
                                                        @click="
                                                            goToUserForm(
                                                                r.user_id,
                                                                'agent'
                                                            )
                                                        "
                                                        class="link-username"
                                                        >
                                                        <span :class="`lvl lvl-${r.user.agent.level}`">{{$t(`level ${r.user.agent.level}`)}}</span>
                                                        <!-- <i
                                                            :class="`mdi mdi-numeric-${
                                                                r.user.agent
                                                                    .level || 0
                                                            }-box fs-5 level-${
                                                                r.user.agent
                                                                    .level || 0
                                                            }`">
                                                        </i> -->
                                                        {{ r.user.username }}
                                                    </a>
                                                </div>
                                                <div>
                                                    <div>
                                                        {{
                                                            numberString(
                                                                r.amount
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-warning':
                                                                r.status ==
                                                                'pending',
                                                            'text-info':
                                                                r.status ==
                                                                'review',
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'complete',
                                                            'text-danger':
                                                                r.status ==
                                                                'cancel',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !agent_deposit_data.list.length &&
                                    !agent_deposit_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="agent_deposit_data"
                                    @emitPage="initOfflineDeposit"
                                    :small="true"
                                /> -->
                    </div>
                    <div class="col-xl-6 ps-1 pb-1 dashboard-transaction">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 100%">
                            <LoadingTable
                                :cols="7"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="agent_withdraw_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="agent-withdraw">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="9">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t('store exchange')
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/agent/transaction/withdraw">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <!-- <th scope="col">
                                            {{ $t('referrer') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('requested money') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('physical bank') }}
                                        </th>
                                        <!-- <th scope="col" class="">
                                            {{ $t('virtual bank') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in agent_withdraw_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'agent'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'agent'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><span :class="`lvl lvl-${r.user.agent.level}`">{{$t(`level ${r.user.agent.level}`)}}</span>
                                                    {{ r.user.username }}
                                                </a>
                                            </div>
                                        </td>

                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                        ? r.handler_bank
                                                              .bank_name + '/'
                                                        : ''
                                                }}
                                                {{
                                                    r.handler_bank
                                                        .account_number
                                                        ? r.handler_bank
                                                              .account_number
                                                        : ''
                                                }}{{
                                                    r.handler_bank
                                                        .account_holder
                                                        ? '/' +
                                                          r.handler_bank
                                                              .account_holder
                                                        : ''
                                                }}
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            {{
                                                r.customer_bank.bank_name
                                                    ? r.customer_bank
                                                          .bank_name + '/'
                                                    : ''
                                            }}
                                            {{
                                                r.customer_bank.account_number
                                                    ? r.customer_bank
                                                          .account_number
                                                    : ''
                                            }}{{
                                                r.customer_bank.account_holder
                                                    ? '/' +
                                                      r.customer_bank
                                                          .account_holder
                                                    : ''
                                            }}
                                        </td> -->
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div class="td-username">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-secondary"
                                                        @click="
                                                            goToTicketForm(
                                                                r.user.username,
                                                                'agent'
                                                            )
                                                        ">
                                                        <i
                                                            class="bx bx-envelope bx-tada-hover fs-4"></i
                                                    ></a>

                                                    <a
                                                        :title="
                                                            $t(`click to view`)
                                                        "
                                                        href="javascript:void(0)"
                                                        @click="
                                                            goToUserForm(
                                                                r.user_id,
                                                                'agent'
                                                            )
                                                        "
                                                        class="link-username"
                                                        ><span :class="`lvl lvl-${r.user.agent.level}`">{{$t(`level ${r.user.agent.level}`)}}</span>
                                                        {{ r.user.username }}
                                                    </a>
                                                </div>
                                                <div>
                                                    <div>
                                                        {{
                                                            numberString(
                                                                r.amount
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-warning':
                                                                r.status ==
                                                                'pending',
                                                            'text-info':
                                                                r.status ==
                                                                'review',
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'complete',
                                                            'text-danger':
                                                                r.status ==
                                                                'cancel',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !agent_withdraw_data.list.length &&
                                    !agent_withdraw_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="agent_withdraw_data"
                                    @emitPage="initOfflineWithdraw"
                                    :small="true"
                                /> -->
                    </div>
                    <div
                        class="col-xl-6 pe-1"
                        v-if="['all', 'online'].includes(player_mode)&&false">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="4"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="online_deposit_loading" />

                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="player-deposit">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="8">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t(
                                                            'member charging (online)'
                                                        )
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/player/transaction/deposit/online">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('referrer') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('charging amount') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('account details') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in online_deposit_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                               
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        v-if="r.user"
                                                        :class="`mdi mdi-numeric-${
                                                            r.user.player
                                                                .level || 0
                                                        }-box fs-5 level-${
                                                            r.user.player
                                                                .level || 0
                                                        }`">
                                                    </i>
                                                    {{ r.user ? r.user.username : 'deleted user' }}
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user && r.user.agent_referrer">
                                                <i
                                                    class="mdi mdi-account-tie text-danger fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user ? r.user.agent_referrer
                                                            .username : 'deleted user'
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user && r.user.player_referrer">
                                                <i
                                                    class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user ? r.user.player_referrer
                                                            .username : 'deleted user'
                                                    }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>

                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                }}/{{
                                                    r.handler_bank
                                                        .account_number
                                                }}/
                                                {{
                                                    r.handler_bank
                                                        .account_holder
                                                }}
                                            </div>
                                        </td>

                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !online_deposit_data.list.length &&
                                    !online_deposit_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="online_deposit_data"
                                    @emitPage="initOnlineDeposit"
                                    :small="true"
                                /> -->
                    </div>
                    <div
                        class="col-xl-6 ps-1"
                        v-if="['all', 'online'].includes(player_mode)&&false">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="7"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="online_withdraw_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0"
                                id="player-withdraw">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="9">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t(
                                                            'member exchange (online)'
                                                        )
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/player/transaction/withdraw/online">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('referrer') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('requested money') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('account details') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in online_withdraw_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${
                                                            r.user.player
                                                                .level || 0
                                                        }-box fs-5 level-${
                                                            r.user.player
                                                                .level || 0
                                                        }`">
                                                    </i>
                                                    {{ r.user.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.agent_referrer">
                                                <i
                                                    class="mdi mdi-account-tie text-danger fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.agent_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.player_referrer">
                                                <i
                                                    class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.player_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>

                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                }}/{{
                                                    r.handler_bank
                                                        .account_number
                                                }}/
                                                {{
                                                    r.handler_bank
                                                        .account_holder
                                                }}
                                            </div>
                                        </td>

                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !online_withdraw_data.list.length &&
                                    !online_withdraw_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="online_withdraw_data"
                                    @emitPage="initOnlineWithdraw"
                                    :small="true"
                                /> -->
                    </div>
                    <div
                        class="col-xl-6 pe-1 pb-1 dashboard-transaction"
                        v-if="['all', 'offline'].includes(player_mode) && false">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 100%">
                            <LoadingTable
                                :cols="4"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="offline_deposit_loading" />

                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="player-deposit">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="8">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t(
                                                            'member charging (offline)'
                                                        )
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/player/transaction/deposit/offline">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('referrer') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('charging amount') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('physical bank') }}
                                        </th>
                                        <!-- <th scope="col" class="">
                                            {{ $t('virtual bank') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in offline_deposit_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${
                                                            r.user.player
                                                                .level || 0
                                                        }-box fs-5 level-${
                                                            r.user.player
                                                                .level || 0
                                                        }`">
                                                    </i>
                                                    {{ r.user.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.agent_referrer">
                                                <i
                                                    class="mdi mdi-account-tie text-danger fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.agent_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.player_referrer">
                                                <i
                                                    class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.player_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                        ? r.handler_bank
                                                              .bank_name + '/'
                                                        : ''
                                                }}
                                                {{
                                                    r.handler_bank
                                                        .account_number
                                                        ? r.handler_bank
                                                              .account_number
                                                        : ''
                                                }}{{
                                                    r.handler_bank
                                                        .account_holder
                                                        ? '/' +
                                                          r.handler_bank
                                                              .account_holder
                                                        : ''
                                                }}
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            {{
                                                r.customer_bank.bank_name
                                                    ? r.customer_bank
                                                          .bank_name + '/'
                                                    : ''
                                            }}
                                            {{
                                                r.customer_bank.account_number
                                                    ? r.customer_bank
                                                          .account_number
                                                    : ''
                                            }}{{
                                                r.customer_bank.account_holder
                                                    ? '/' +
                                                      r.customer_bank
                                                          .account_holder
                                                    : ''
                                            }}
                                        </td> -->
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div class="td-username">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-secondary"
                                                        @click="
                                                            goToTicketForm(
                                                                r.user.username,
                                                                'player'
                                                            )
                                                        ">
                                                        <i
                                                            class="bx bx-envelope bx-tada-hover fs-4"></i
                                                    ></a>

                                                    <a
                                                        :title="
                                                            $t(`click to view`)
                                                        "
                                                        href="javascript:void(0)"
                                                        @click="
                                                            goToUserForm(
                                                                r.user_id,
                                                                'player'
                                                            )
                                                        "
                                                        class="link-username"
                                                        ><i
                                                            :class="`mdi mdi-numeric-${
                                                                r.user.player
                                                                    .level || 0
                                                            }-box fs-5 level-${
                                                                r.user.player
                                                                    .level || 0
                                                            }`">
                                                        </i>
                                                        {{ r.user.username }}
                                                    </a>
                                                </div>
                                                <div>
                                                    <div>
                                                        {{
                                                            numberString(
                                                                r.amount
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-warning':
                                                                r.status ==
                                                                'pending',
                                                            'text-info':
                                                                r.status ==
                                                                'review',
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'complete',
                                                            'text-danger':
                                                                r.status ==
                                                                'cancel',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !offline_deposit_data.list.length &&
                                    !offline_deposit_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="offline_deposit_data"
                                    @emitPage="initOfflineDeposit"
                                    :small="true"
                                /> -->
                    </div>
                    <div
                        class="col-xl-6 ps-1 pb-1 dashboard-transaction"
                        v-if="['all', 'offline'].includes(player_mode) && false">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 100%">
                            <LoadingTable
                                :cols="7"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="offline_withdraw_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="player-withdraw">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="9">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t(
                                                            'member exchange (offline)'
                                                        )
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/player/transaction/withdraw/offline">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('referrer') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('requested money') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('physical bank') }}
                                        </th>
                                        <!-- <th scope="col" class="">
                                            {{ $t('virtual bank') }}
                                        </th> -->
                                        <th scope="col">
                                            {{ $t('application date') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('processing date') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in offline_withdraw_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning':
                                                        r.status == 'pending',
                                                    'text-info':
                                                        r.status == 'review',
                                                    'text-success fw-bold':
                                                        r.status == 'complete',
                                                    'text-danger':
                                                        r.status == 'cancel',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>

                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${
                                                            r.user.player
                                                                .level || 0
                                                        }-box fs-5 level-${
                                                            r.user.player
                                                                .level || 0
                                                        }`">
                                                    </i>
                                                    {{ r.user.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.agent_referrer">
                                                <i
                                                    class="mdi mdi-account-tie text-danger fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.agent_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-1"
                                                v-if="r.user.player_referrer">
                                                <i
                                                    class="mdi mdi-account-cowboy-hat text-primary fs-5"></i>
                                                <span class="mb-0 lh-1">
                                                    {{
                                                        r.user.player_referrer
                                                            .username
                                                    }}
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            <div v-if="r.handler_bank != null">
                                                {{
                                                    r.handler_bank.bank_name
                                                        ? r.handler_bank
                                                              .bank_name + '/'
                                                        : ''
                                                }}
                                                {{
                                                    r.handler_bank
                                                        .account_number
                                                        ? r.handler_bank
                                                              .account_number
                                                        : ''
                                                }}{{
                                                    r.handler_bank
                                                        .account_holder
                                                        ? '/' +
                                                          r.handler_bank
                                                              .account_holder
                                                        : ''
                                                }}
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            {{
                                                r.customer_bank.bank_name
                                                    ? r.customer_bank
                                                          .bank_name + '/'
                                                    : ''
                                            }}
                                            {{
                                                r.customer_bank.account_number
                                                    ? r.customer_bank
                                                          .account_number
                                                    : ''
                                            }}{{
                                                r.customer_bank.account_holder
                                                    ? '/' +
                                                      r.customer_bank
                                                          .account_holder
                                                    : ''
                                            }}
                                        </td> -->
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.updated_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div class="td-username">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-secondary"
                                                        @click="
                                                            goToTicketForm(
                                                                r.user.username,
                                                                'player'
                                                            )
                                                        ">
                                                        <i
                                                            class="bx bx-envelope bx-tada-hover fs-4"></i
                                                    ></a>

                                                    <a
                                                        :title="
                                                            $t(`click to view`)
                                                        "
                                                        href="javascript:void(0)"
                                                        @click="
                                                            goToUserForm(
                                                                r.user_id,
                                                                'player'
                                                            )
                                                        "
                                                        class="link-username"
                                                        ><i
                                                            :class="`mdi mdi-numeric-${
                                                                r.user.player
                                                                    .level || 0
                                                            }-box fs-5 level-${
                                                                r.user.player
                                                                    .level || 0
                                                            }`">
                                                        </i>
                                                        {{ r.user.username }}
                                                    </a>
                                                </div>
                                                <div>
                                                    <div>
                                                        {{
                                                            numberString(
                                                                r.amount
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-warning':
                                                                r.status ==
                                                                'pending',
                                                            'text-info':
                                                                r.status ==
                                                                'review',
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'complete',
                                                            'text-danger':
                                                                r.status ==
                                                                'cancel',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !offline_withdraw_data.list.length &&
                                    !offline_withdraw_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="offline_withdraw_data"
                                    @emitPage="initOfflineWithdraw"
                                    :small="true"
                                /> -->
                    </div>
                    <div class="col-xl-12">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="5"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="player_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="players">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="5">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{
                                                        $t(
                                                            'member registration information'
                                                        )
                                                    }}</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/player/management">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('level') }}/
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('subscription date') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('access ip') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('access domain') }}
                                        </th>
                                        <th scope="col" class="">
                                            {{ $t('login status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in player_data.list"
                                        :key="r._id">
                                        <td class="text-start">
                                            <div class="td-username">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r._id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username"
                                                    ><i
                                                        :class="`mdi mdi-numeric-${r.player.level}-box fs-5 level-${r.player.level}`">
                                                    </i>
                                                    {{ r.username }}
                                                    <i
                                                        class="bx bxs-circle text-warning font-size-11"
                                                        v-if="r.logged_in"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="text-truncate d-flex align-items-center"
                                                v-if="r.last_login_ip">
                                                <i
                                                    class="mdi mdi-ip-network-outline fs-5"></i>
                                                {{ r.last_login_ip }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ playerDomain }}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-warning': r.logged_in,
                                                    'text-secondary':
                                                        !r.logged_in,
                                                }"
                                                >{{
                                                    $t(
                                                        r.logged_in
                                                            ? 'login'
                                                            : 'logout'
                                                    )
                                                }}</span
                                            >
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div>
                                                    <div class="td-username">
                                                        <a
                                                            href="javascript:void(0)"
                                                            class="text-secondary"
                                                            @click="
                                                                goToTicketForm(
                                                                    r.username,
                                                                    'player'
                                                                )
                                                            ">
                                                            <i
                                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                                        ></a>

                                                        <a
                                                            :title="
                                                                $t(
                                                                    `click to view`
                                                                )
                                                            "
                                                            href="javascript:void(0)"
                                                            @click="
                                                                goToUserForm(
                                                                    r._id,
                                                                    'player'
                                                                )
                                                            "
                                                            class="link-username"
                                                            ><i
                                                                :class="`mdi mdi-numeric-${r.player.level}-box fs-5 level-${r.player.level}`">
                                                            </i>
                                                            {{ r.username }}
                                                            <i
                                                                class="bx bxs-circle text-warning font-size-11"
                                                                v-if="
                                                                    r.logged_in
                                                                "></i>
                                                        </a>
                                                    </div>
                                                    <div
                                                        class="text-truncate d-flex align-items-center"
                                                        v-if="r.last_login_ip">
                                                        {{ r.last_login_ip }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !player_data.list.length && !player_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="player_data"
                                    @emitPage="initBet"
                                    :small="true"
                                /> -->
                    </div>

                    <div
                        :class="{
                            'col-xl-6 pe-1': ['all', 'online'].includes(
                                player_mode
                            ),
                            'col-xl-12': ['offline'].includes(player_mode),
                        }">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="7"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="agent_ticket_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="tickets">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="7">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{ $t('customer service') }}
                                                    ({{ $t('agent') }})</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/tickets/agent">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('subject') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('creation date') }}/{{
                                                $t('reply date')
                                            }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in agent_ticket_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-success fw-bold':
                                                        r.status == 'open',
                                                    'text-danger':
                                                        r.status == 'closed',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                        <!-- <td></td> -->
                                        <td class="text-start">
                                            <div class="td-username">
                                                <!-- <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.customer.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a> -->

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.customer._id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username">
                                                    <!-- <i
                                                        :class="`mdi mdi-numeric-${0}-box fs-5 level-${0}`">
                                                    </i> -->
                                                    {{ r.customer.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <a
                                                href="javascript:void(0)"
                                                class="text-dark d-flex align-items-center"
                                                @click="goToTicketView(r._id)">
                                                <i
                                                    :class="`mdi ${
                                                        !r.handler_read
                                                            ? 'mdi-email text-warning'
                                                            : 'mdi-email-open-outline'
                                                    } fs-5 `"></i>
                                                <span
                                                    class="text-truncate me-2"
                                                    :class="{
                                                        'fw-bold':
                                                            !r.handler_read,
                                                    }"
                                                    style="max-width: 500px">
                                                    {{ r.subject }}
                                                </span>
                                                <span
                                                    class="text-muted font-size-11"
                                                    >{{
                                                        $t('click to view')
                                                    }}</span
                                                >
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-dark d-flex align-items-center"
                                                        @click="
                                                            goToTicketView(
                                                                r._id
                                                            )
                                                        ">
                                                        <i
                                                            :class="`mdi ${
                                                                !r.handler_read
                                                                    ? 'mdi-email text-warning'
                                                                    : 'mdi-email-open-outline'
                                                            } fs-5 `"></i>
                                                        <span
                                                            class="text-truncate me-2"
                                                            :class="{
                                                                'fw-bold':
                                                                    !r.handler_read,
                                                            }"
                                                            style="
                                                                max-width: 500px;
                                                            ">
                                                            {{ r.subject }}
                                                        </span>
                                                        <span
                                                            class="text-muted font-size-11"
                                                            >{{
                                                                $t(
                                                                    'click to view'
                                                                )
                                                            }}</span
                                                        >
                                                    </a>
                                                </div>
                                                <div>
                                                    <div
                                                        class="td-username justify-content-end">
                                                        <!-- <a
                                                            href="javascript:void(0)"
                                                            class="text-secondary"
                                                            @click="
                                                                goToTicketForm(
                                                                    r.customer
                                                                        .username,
                                                                    'player'
                                                                )
                                                            ">
                                                            <i
                                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                                        ></a> -->

                                                        <a
                                                            :title="
                                                                $t(
                                                                    `click to view`
                                                                )
                                                            "
                                                            href="javascript:void(0)"
                                                            @click="
                                                                goToUserForm(
                                                                    r.customer
                                                                        ._id,
                                                                    'player'
                                                                )
                                                            "
                                                            class="link-username">
                                                            <!-- <i
                                                                :class="`mdi mdi-numeric-${0}-box fs-5 level-${0}`">
                                                            </i> -->
                                                            {{
                                                                r.customer
                                                                    .username
                                                            }}
                                                        </a>
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'open',
                                                            'text-danger':
                                                                r.status ==
                                                                'closed',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !agent_ticket_data.list.length &&
                                    !agent_ticket_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="agent_ticket_data"
                                    @emitPage="initAgentTicket"
                                    :small="true"
                                /> -->
                    </div>
                    <div
                        class="col-xl-6 ps-1"
                        v-if="['all', 'online'].includes(player_mode)">
                        <div
                            ref="tableScroller"
                            class="table-responsive"
                            style="min-height: 250px">
                            <LoadingTable
                                :cols="7"
                                :rows="6"
                                :title_thead="true"
                                :td_small_class="`d-none`"
                                class="table-bordered table-striped"
                                v-if="player_ticket_loading" />
                            <table
                                v-else
                                class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list"
                                id="tickets">
                                <thead>
                                    <tr class="fs-5 bg-light bg-soft">
                                        <th class="text-start" colspan="7">
                                            <div
                                                class="d-flex justify-content-between align-items-center">
                                                <span class="table-title">
                                                    {{ $t('customer service') }}
                                                    ({{ $t('player') }})</span
                                                >
                                                <router-link
                                                    class="text-dark font-size-11 shortcut-link"
                                                    to="/tickets/player">
                                                    {{ $t('see more') }}
                                                </router-link>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="hidden">
                                        <th scope="col">
                                            {{ $t('status') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('username') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('subject') }}
                                        </th>
                                        <th scope="col">
                                            {{ $t('creation date') }}/{{
                                                $t('reply date')
                                            }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in player_ticket_data.list"
                                        :key="r._id">
                                        <td class="text-center">
                                            <span
                                                :class="{
                                                    'text-success fw-bold':
                                                        r.status == 'open',
                                                    'text-danger':
                                                        r.status == 'closed',
                                                }"
                                                >{{ $t(r.status) }}</span
                                            >
                                        </td>
                                        <!-- <td></td> -->
                                        <td class="text-start">
                                            <div class="td-username">
                                                <!-- <a
                                                    href="javascript:void(0)"
                                                    class="text-secondary"
                                                    @click="
                                                        goToTicketForm(
                                                            r.customer.username,
                                                            'player'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a> -->

                                                <a
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.customer._id,
                                                            'player'
                                                        )
                                                    "
                                                    class="link-username">
                                                    <!-- <i
                                                        :class="`mdi mdi-numeric-${0}-box fs-5 level-${0}`">
                                                    </i> -->
                                                    {{ r.customer.username }}
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <a
                                                href="javascript:void(0)"
                                                class="text-dark d-flex align-items-center"
                                                @click="goToTicketView(r._id)">
                                                <i
                                                    :class="`mdi ${
                                                        !r.handler_read
                                                            ? 'mdi-email text-warning'
                                                            : 'mdi-email-open-outline'
                                                    } fs-5 `"></i>
                                                <span
                                                    class="text-truncate me-2"
                                                    :class="{
                                                        'fw-bold':
                                                            !r.handler_read,
                                                    }"
                                                    style="max-width: 500px">
                                                    {{ r.subject }}
                                                </span>
                                                <span
                                                    class="text-muted font-size-11"
                                                    >{{
                                                        $t('click to view')
                                                    }}</span
                                                >
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ dateYmD(r.created_at, true) }}
                                        </td>
                                        <td class="mobile">
                                            <div class="cell-card">
                                                <div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="text-dark d-flex align-items-center"
                                                        @click="
                                                            goToTicketView(
                                                                r._id
                                                            )
                                                        ">
                                                        <i
                                                            :class="`mdi ${
                                                                !r.handler_read
                                                                    ? 'mdi-email text-warning'
                                                                    : 'mdi-email-open-outline'
                                                            } fs-5 `"></i>
                                                        <span
                                                            class="text-truncate me-2"
                                                            :class="{
                                                                'fw-bold':
                                                                    !r.handler_read,
                                                            }"
                                                            style="
                                                                max-width: 500px;
                                                            ">
                                                            {{ r.subject }}
                                                        </span>
                                                        <span
                                                            class="text-muted font-size-11"
                                                            >{{
                                                                $t(
                                                                    'click to view'
                                                                )
                                                            }}</span
                                                        >
                                                    </a>
                                                </div>
                                                <div>
                                                    <div
                                                        class="td-username justify-content-end">
                                                        <!-- <a
                                                            href="javascript:void(0)"
                                                            class="text-secondary"
                                                            @click="
                                                                goToTicketForm(
                                                                    r.customer
                                                                        .username,
                                                                    'player'
                                                                )
                                                            ">
                                                            <i
                                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                                        ></a> -->

                                                        <a
                                                            :title="
                                                                $t(
                                                                    `click to view`
                                                                )
                                                            "
                                                            href="javascript:void(0)"
                                                            @click="
                                                                goToUserForm(
                                                                    r.customer
                                                                        ._id,
                                                                    'player'
                                                                )
                                                            "
                                                            class="link-username">
                                                            <!-- <i
                                                                :class="`mdi mdi-numeric-${0}-box fs-5 level-${0}`">
                                                            </i> -->
                                                            {{
                                                                r.customer
                                                                    .username
                                                            }}
                                                        </a>
                                                    </div>
                                                    <div class="text-muted">
                                                        {{
                                                            dateYmD(
                                                                r.created_at,
                                                                true
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        :class="{
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'open',
                                                            'text-danger':
                                                                r.status ==
                                                                'closed',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                class="no-data-placeholder"
                                style="min-height: 220px"
                                v-show="
                                    !player_ticket_data.list.length &&
                                    !player_ticket_loading
                                ">
                                {{ $t('no data found') }}
                            </div>
                        </div>
                        <!-- <Pagination
                                    :data="agent_ticket_data"
                                    @emitPage="initAgentTicket"
                                    :small="true"
                                /> -->
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import { mapActions } from 'vuex';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import random from '../../../mixins/random';
export default {
    components: {
        Layout,
        PageHeader,
        LoadingTable,
        Pagination,
    },
    mixins: [datetime, number, ui, random],
    data() {
        return {
            submitted: false,
            loading: false,
            online_deposit_loading: false,
            online_deposit_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            online_withdraw_loading: false,
            online_withdraw_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            offline_deposit_loading: false,
            offline_deposit_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            offline_withdraw_loading: false,
            offline_withdraw_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            agent_deposit_loading: false,
            agent_deposit_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            agent_withdraw_loading: false,
            agent_withdraw_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            player_loading: false,
            player_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            agent_ticket_loading: false,
            agent_ticket_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            player_ticket_loading: false,
            player_ticket_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
        };
    },
    computed: {
        playerDomain() {
            return window.location.host.replace('platform.', '');
        },
    },
    watch: {
        '$store.state.key'() {
            this.initOnlineDeposit();
            this.initOnlineWithdraw();
            this.initOfflineDeposit();
            this.initOfflineWithdraw();
            this.initPlayer();
            this.initAgentTicket();
            this.initPlayerTicket();
        },
    },
    methods: {
        ...mapActions('transaction', {
            transactionGetList: 'getList',
        }),
        ...mapActions('player', {
            playerGetList: 'getList',
        }),
        ...mapActions('ticket', {
            ticketGetList: 'getList',
        }),
        async initOnlineDeposit(p) {
            var pl = {
                user: 'player',
                type: 'deposit',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: true,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.online_deposit_loading = true;
            const data = await this.transactionGetList(pl);
            this.online_deposit_data.list = data.data;
            this.online_deposit_data.links = data.links;
            this.online_deposit_data.current_page = data.current_page;
            this.online_deposit_data.per_page = data.per_page;
            this.online_deposit_data.last_page = data.last_page;
            this.online_deposit_loading = false;
        },
        async initOnlineWithdraw(p) {
            var pl = {
                user: 'player',
                type: 'withdraw',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: true,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.online_withdraw_loading = true;
            const data = await this.transactionGetList(pl);
            this.online_withdraw_data.list = data.data;
            this.online_withdraw_data.links = data.links;
            this.online_withdraw_data.current_page = data.current_page;
            this.online_withdraw_data.per_page = data.per_page;
            this.online_withdraw_data.last_page = data.last_page;
            this.online_withdraw_loading = false;
        },
        async initOfflineDeposit(p) {
            var pl = {
                user: 'player',
                type: 'deposit',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: false,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.offline_deposit_loading = true;
            const data = await this.transactionGetList(pl);
            this.offline_deposit_data.list = data.data;
            this.offline_deposit_data.links = data.links;
            this.offline_deposit_data.current_page = data.current_page;
            this.offline_deposit_data.per_page = data.per_page;
            this.offline_deposit_data.last_page = data.last_page;
            this.offline_deposit_loading = false;
        },
        async initOfflineWithdraw(p) {
            var pl = {
                user: 'player',
                type: 'withdraw',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: false,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.offline_withdraw_loading = true;
            const data = await this.transactionGetList(pl);
            this.offline_withdraw_data.list = data.data;
            this.offline_withdraw_data.links = data.links;
            this.offline_withdraw_data.current_page = data.current_page;
            this.offline_withdraw_data.per_page = data.per_page;
            this.offline_withdraw_data.last_page = data.last_page;
            this.offline_withdraw_loading = false;
        },
        async initAgentDeposit(p) {
            var pl = {
                user: 'agent',
                type: 'deposit',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: false,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.agent_deposit_loading = true;
            const data = await this.transactionGetList(pl);
            this.agent_deposit_data.list = data.data;
            this.agent_deposit_data.links = data.links;
            this.agent_deposit_data.current_page = data.current_page;
            this.agent_deposit_data.per_page = data.per_page;
            this.agent_deposit_data.last_page = data.last_page;
            this.agent_deposit_loading = false;
        },
        async initAgentWithdraw(p) {
            var pl = {
                user: 'agent',
                type: 'withdraw',
                // from: this.dateYmD(new Date()) + ' 00:00:00',
                // to: this.dateYmD(new Date()) + ' 23:59:59',
                online: false,
                limit: 6,
                page_no: p ? p : 1,
            };
            this.agent_withdraw_loading = true;
            const data = await this.transactionGetList(pl);
            this.agent_withdraw_data.list = data.data;
            this.agent_withdraw_data.links = data.links;
            this.agent_withdraw_data.current_page = data.current_page;
            this.agent_withdraw_data.per_page = data.per_page;
            this.agent_withdraw_data.last_page = data.last_page;
            this.agent_withdraw_loading = false;
        },
        async initPlayer(p) {
            var pl = {
                limit: 6,
                page_no: p ? p : 1,
                sort: 'created_at',
                order: 'desc',
            };
            this.player_loading = true;
            const data = await this.playerGetList(pl);
            this.player_data.list = data.data;
            this.player_data.links = data.links;
            this.player_data.current_page = data.current_page;
            this.player_data.per_page = data.per_page;
            this.player_data.last_page = data.last_page;
            this.player_loading = false;
        },
        async initAgentTicket(p) {
            var pl = {
                read: 1,
                limit: 6,
                page_no: p ? p : 1,
                role: 'agent',
            };
            this.agent_ticket_loading = true;
            const data = await this.ticketGetList(pl);
            this.agent_ticket_data.list = data.data;
            this.agent_ticket_data.links = data.links;
            this.agent_ticket_data.current_page = data.current_page;
            this.agent_ticket_data.per_page = data.per_page;
            this.agent_ticket_data.last_page = data.last_page;
            this.agent_ticket_loading = false;
        },
        async initPlayerTicket(p) {
            var pl = {
                read: 1,
                limit: 6,
                page_no: p ? p : 1,
                role: 'player',
            };
            this.player_ticket_loading = true;
            const data = await this.ticketGetList(pl);
            this.player_ticket_data.list = data.data;
            this.player_ticket_data.links = data.links;
            this.player_ticket_data.current_page = data.current_page;
            this.player_ticket_data.per_page = data.per_page;
            this.player_ticket_data.last_page = data.last_page;
            this.player_ticket_loading = false;
        },
    },
    mounted() {
        this.initOnlineDeposit();
        this.initOnlineWithdraw();
        this.initOfflineDeposit();
        this.initOfflineWithdraw();
        this.initAgentDeposit();
        this.initAgentWithdraw();
        this.initPlayer();
        this.initPlayerTicket();
        this.initAgentTicket();
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('new-ticket', (e) => {
                if (e.message.role === 'player') {
                    this.initPlayerTicket();
                } else {
                    this.initAgentTicket();
                }
            });
            window.PusherChannel.bind('new-deposit', (e) => {
                if (e.message.role === 'player') {
                    this.initOnlineDeposit();
                    this.initOfflineDeposit();
                } else {
                    this.initAgentDeposit();
                }
            });
            window.PusherChannel.bind('new-withdraw', (e) => {
                if (e.message.role === 'player') {
                    this.initOnlineWithdraw();
                    this.initOfflineWithdraw();
                } else {
                    this.initAgentWithdraw();
                }
            });
            window.PusherChannel.bind('new-player-registration', (e) => {
                this.initPlayer();
            });
        }
        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-tickets') {
                this.authGetProfile();
                // this.initAgentTicket();
            }
        });
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('new-ticket');
            window.PusherChannel.unbind('new-deposit');
            window.PusherChannel.unbind('new-withdraw');
            window.PusherChannel.unbind('new-player-registration');
        }
    },
};
</script>
